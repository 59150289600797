// import { api } from './../../components/axios/api'

export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE `'

const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
})

// const fetchProductsFailure = (error) => ({
//   type: FETCH_PRODUCTS_FAILURE,
//   payload: { error: error.message },
// })

// fetch products
// export const fetchProducts = () => async (dispatch) => {
//   try {
//     const products = await api.get('/public/product?offset=1&limit=10')

//     dispatch(fetchProductsSuccess(products))
//   } catch (error) {
//     // dispatch(fetchProductsFailure(error))
//     console.log(error)
//   }
// }

// export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'

// const fetchProductsSuccess = (products) => ({
//   type: FETCH_PRODUCTS_SUCCESS,
//   payload: products,
// })

// fetch products
export const fetchProducts = (products) => {
  return (dispatch) => {
    dispatch(fetchProductsSuccess(products))
  }
}
