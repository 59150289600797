import {
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_SUCCESS,
} from '../actions/productActions'

const initState = {
  products: [],
  error: null,
}

const productReducer = (state = initState, action) => {
  // const getProducts(action.payload)
  if (action.type === FETCH_PRODUCTS_SUCCESS) {
    return {
      ...state,
      products: action.payload,
    }
  }
  if (action.type === FETCH_PRODUCTS_FAILURE) {
    return {
      ...state,
      error: action.payload.error,
    }
  }

  return state
}

export default productReducer
