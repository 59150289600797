import axios from 'axios'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const REGISTER = 'REGISTER'

export const login = (userData, history) => async (dispatch) => {
  // if (addToast) {
  //   addToast('Added To Cart', { appearance: 'success', autoDismiss: true })
  // }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  const response = await axios.post('/auth/quick-login', userData, config)
  dispatch({
    type: LOGIN_SUCCESS,
    payload: response,
  })

  history.push('/')
}
