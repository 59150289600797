import { LOGIN_SUCCESS } from './../actions/authActions'

const initialState = {
  password: '',
  email: '',
  isLogin: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      console.log(action.payload)
      console.log('i worked')
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
        isLogin: true,
      }

    default:
      return state
  }
}

export default authReducer
